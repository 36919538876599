import agent from "../../app/api/agent";
import {useState} from "react";
function AuditLogs() {
    const [result, setResult] = useState<any>(null);
    const fetchProtectedData = async (): Promise<any> => {
        try {
            const response = await agent.get('/admin/getServerResponseTime');
            return response.data;
        } catch (error) {
            console.error('Error fetching protected data', error);
        }
    };
    const handleSubmit = async (e:any) => {
        e.preventDefault();
        let data = await fetchProtectedData();
        setResult(data.status);
        console.log("data",data.status)
    };
    const divStyle = {
        index: 99999,
        backgroundColor: 'blue',
        padding: '20px',
        borderRadius: '5px',
        top:'300px'
    };

    return (
        <section>
            <div className="login-container">
                <form onSubmit={handleSubmit} className="login-form">
                    Test Page
                    <button type="submit">Get Secure Data</button>
                    <button type="button" onClick={() => setResult(null)}>Clear</button>
                </form>
                <div className="container" style={divStyle}>
                    <div>
                        <pre>{JSON.stringify(result, null, 2)}</pre>
                    </div>
                </div>
            </div>

        </section>
    );
}

export default AuditLogs;