function AboutPage() {
    return (
        <div style={styles.container}>
            <h2 style={styles.title}>About</h2>
        </div>
    )
}
const styles: { [key: string]: React.CSSProperties } = {
    container: {
        padding: '20px',
        margin: '0 auto',
        maxWidth: '800px',
    },
    title: {
        marginBottom: '16px',
        fontSize: '2em',
    },
};
export default AboutPage;