
import {JwtType} from "../types";
import {jwtDecode} from "jwt-decode";
interface DecodedToken {
    id: string;
    email: string;
    exp: number;
    iat: number;
}
// Function to get access token from storage
export const getAccessToken = (): string | null => {
    return localStorage.getItem('access_token');
};

// Function to get refresh token from storage
export const getRefreshToken = (): string | null => {
    return localStorage.getItem('refresh_token');
};

// Function to save new tokens to storage
export const saveTokens = (accessToken: string, refreshToken: string): void => {
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('refresh_token', refreshToken);
};
// Function to decode jwt
export const decodeJwt = (tokenType: string): DecodedToken | null => {
    try {
        const token = tokenType === JwtType.ACCESS ? getAccessToken() : getRefreshToken();
        if (!token) return null;
        return jwtDecode<DecodedToken>(token);
    } catch (error) {
        return null;
    }
};
export const removeTokens = (): void => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
};

