import React, {useState, ChangeEvent, FormEvent, useEffect, useRef} from 'react';
import {login,validate} from './authSlice';
import '../../styles/login/css/style.scss';
import LoginPageHeader from './LoginPageHeader';
import LoginPageFooter from './LoginPageFooter';
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../app/store/configureStore";
import {getAccessToken} from "../../services/jwtService";

interface FormData {
    username: string;
    password: string;
}

interface FormErrors {
    username: string;
    password: string;
}

function Login() {
    const [formData, setFormData] = useState<FormData>({username: '', password: ''});
    const [errors, setErrors] = useState<FormErrors>({username: '', password: ''});
    const [loading, setLoading] = useState<boolean>(true);
    const dispatch = useDispatch<AppDispatch>();
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

    const API_URL = process.env.REACT_APP_API_URL;
    const DASHBOARD_URL = process.env.REACT_APP_DASHBOARD_URI;
    const effectRan = useRef(false);
    useEffect(() => {
        if (effectRan.current) return;
        const validateTokens = async () => {
            try {
                if (isAuthenticated) {
                    let isValid = await dispatch(validate());
                    if (isValid) {
                        window.location.href = API_URL + DASHBOARD_URL + `?token=${getAccessToken()}`;
                        return true;
                    } else {
                        setLoading(false);
                    }
                } else {
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error validate:', error);
                setLoading(false);
            }
            return false;
        };
        validateTokens().then(isAuthenticated => console.log('isAuthenticated',isAuthenticated) );
        effectRan.current = true;
    }, [API_URL, DASHBOARD_URL, dispatch, isAuthenticated]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData((prevData: FormData) => ({
            ...prevData,
            [name]: value
        }));

        // Clear the error for the specific field
        setErrors((prevErrors: FormErrors) => ({
            ...prevErrors,
            [name]: ''
        }));
    };
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            let valid = true;
            let newErrors: FormErrors = {username: '', password: ''};
            if (!formData.username) {
                valid = false;
                newErrors.username = 'Username is required';
            }

            if (!formData.password) {
                valid = false;
                newErrors.password = 'Password is required';
            }
            setErrors(newErrors);
            if (valid) {
                setLoading(true);
                const loginResponse = await dispatch(login(formData.username, formData.password));
                window.location.href = API_URL + DASHBOARD_URL + `?token=${loginResponse.accessToken}`;
            }

        } catch (err) {
            console.error("err:",err)
            setLoading(false);
            toast.error('Login failed. Please check your credentials and try again.', {});
        }
    };
    if (loading) {
        return <div className="login-container"><span className='loader'></span></div>;
    }

    return (
        <section>
            <section className="wrapper">
                <LoginPageHeader/>
                <div className="login">
                    <div className="content">
                        <h1>Login</h1>
                        <form onSubmit={handleSubmit} className="inputMaxWidthXl">
                            <div className="form-group">
                                <label htmlFor="username">Username</label>
                                <input type="text"
                                       value={formData.username}
                                       onChange={handleChange}
                                       placeholder="Username"
                                       id='username'
                                       name='username'
                                       className="form-control"/>
                                {errors.username && <p className="error strong">{errors.username}</p>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <input type="password"
                                       value={formData.password}
                                       onChange={handleChange}
                                       placeholder="Password"
                                       id='password'
                                       name='password'
                                       className="form-control"/>
                                {errors.password && <p className="error">{errors.password}</p>}
                            </div>
                            <div className="submit-block">
                                <button type="submit" className='btn btn-primary'>Login</button>
                            </div>
                            <div className="form-group forgot-password">
                                <a href={API_URL + '/forgotPassword/identify'} className="identify_user">Forgot Login
                                    Details</a>
                            </div>
                        </form>
                    </div>
                </div>
                <LoginPageFooter/>
            </section>
        </section>
    );
}

export default Login;

