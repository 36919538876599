import {createSlice} from '@reduxjs/toolkit';
import {login as authServiceLogin, validateTokens} from '../../services/loginService';
import {decodeJwt, removeTokens} from '../../services/jwtService';
import {jwtDecode} from "jwt-decode";
import {JwtType} from "../../types";

interface DecodedToken {
    id: string;
    email: string;
    exp: number;
    iat: number;
}
export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        accessToken: decodeJwt(JwtType.ACCESS),
        refreshToken: decodeJwt(JwtType.REFRESH),
        isAuthenticated: !!decodeJwt(JwtType.ACCESS),
    },
    reducers: {
        loginSuccess: (state, action) => {
            state.accessToken = action.payload.decodedAccessToken;
            state.refreshToken = action.payload.decodedRefreshToken;
            state.isAuthenticated = true;
        },
        logoutSuccess: (state) => {
            state.accessToken = null;
            state.refreshToken = null;
            state.isAuthenticated = false;
        },
        authenticateStatusUpdate:(state, action) => {
            state.isAuthenticated = action.payload;
        },
    },
});

export const login = (email: string, password: string) => async (dispatch: (arg0: any) => void) => {
    try {
        const loginResponse = await authServiceLogin(email, password);
        const decodedAccessToken = jwtDecode<DecodedToken>(loginResponse.accessToken)
        const decodedRefreshToken = jwtDecode<DecodedToken>(loginResponse.refreshToken)
        dispatch(loginSuccess({decodedAccessToken,decodedRefreshToken}));
        return loginResponse
    } catch (error) {
        throw error;
    }
};
export const validate = () => async (dispatch: (arg0: any) => void) => {
    try {
        const isAuthenticated = await validateTokens();
        dispatch(authenticateStatusUpdate(isAuthenticated));
        if(!isAuthenticated){
            dispatch(logoutSuccess());
            removeTokens();
        }

        return isAuthenticated
    } catch (error) {
        throw error;
    }
};

export const logout = () => (dispatch: (arg0: any) => void) => {
    dispatch(logoutSuccess());
};
export const setTokens = (accessToken: string, refreshToken: string) => async (dispatch: (arg0: any) => void) => {
    try {
        const decodedAccessToken = jwtDecode<DecodedToken>(accessToken)
        const decodedRefreshToken = jwtDecode<DecodedToken>(refreshToken)
        dispatch(loginSuccess({decodedAccessToken,decodedRefreshToken}));
        return true
    } catch (error) {
        throw error;
    }
};

export const {loginSuccess, logoutSuccess,authenticateStatusUpdate} = authSlice.actions;
