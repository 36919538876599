import axios, {AxiosInstance, InternalAxiosRequestConfig, AxiosResponse} from "axios";
import {getAccessToken, getRefreshToken, removeTokens, saveTokens} from "../../services/jwtService";
import {store} from '../store/configureStore';
import {logout, setTokens} from "../../features/login/authSlice";

const API_BASE_URL = process.env.REACT_APP_API_URL;
const REFRESH_TOKEN_URL = process.env.REACT_APP_REFRESH_TOKEN_URI;
const REQUEST_TOKENS_URI = process.env.REACT_APP_REQUEST_TOKENS_TOKEN_URI;
const REACT_APP_LOGIN_URI = process.env.REACT_APP_LOGIN_URI;

axios.defaults.baseURL = API_BASE_URL;
// Create an Axios instance
const agent: AxiosInstance = axios.create({
    baseURL: API_BASE_URL,
});
// Request interceptor to add the access token to headers
agent.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    const token = getAccessToken();
    if (token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    // Handle request error
    return Promise.reject(error);
});
// Response interceptor to handle token expiration and renewal
agent.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response?.status === 401) {
            const retryRequest = async (token: string, refreshToken: string) => {
                saveTokens(token, refreshToken);
                store.dispatch(setTokens(token, refreshToken));
                agent.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                originalRequest.headers['Authorization'] = `Bearer ${token}`;
                return agent(originalRequest);
            };

            if (!originalRequest._retry) {
                originalRequest._retry = true;
                const refreshToken = getRefreshToken();
                if (refreshToken) {
                    try {
                        const response = await axios.get(`${API_BASE_URL}${REFRESH_TOKEN_URL}`, {
                            headers: {'Authorization': `Bearer ${refreshToken}`}
                        });
                        const {accessToken, refreshToken: newRefreshToken} = response?.data;
                        if (accessToken && newRefreshToken) {
                            return await retryRequest(accessToken, newRefreshToken);
                        }
                    } catch (refreshError) {
                    }
                }
            }

            if (!originalRequest._retry2) {
                originalRequest._retry2 = true;
                try {
                    const response = await axios.get(`${API_BASE_URL}${REQUEST_TOKENS_URI}`, {
                        withCredentials: true
                    });
                    const {accessToken, refreshToken: newRefreshToken} = response?.data;

                    if (accessToken && newRefreshToken) {
                        return await retryRequest(accessToken, newRefreshToken);
                    }else{
                        clearTokensAndRedirectToLoginPage();
                    }
                } catch (requestTokensError) {
                    clearTokensAndRedirectToLoginPage();
                }
            }
        }
        function clearTokensAndRedirectToLoginPage() {
            removeTokens();
            store.dispatch(logout());
            if(window.location.pathname !== REACT_APP_LOGIN_URI){
                window.location.href = REACT_APP_LOGIN_URI
            }
        }

        return Promise.reject(error);
    }
);

export default agent;
