import axios, {AxiosResponse} from 'axios';
import {AuthResponse} from "../app/models/AuthResponse";
import {saveTokens, getAccessToken, getRefreshToken} from './jwtService';

interface LoginResponse {
    accessToken: string,
    refreshToken: string
}

const LOGIN_URI = process.env.REACT_APP_API_LOGIN_URI
const API_BASE_URL = process.env.REACT_APP_API_URL
const VALIDATE_URI = process.env.REACT_APP_TOKEN_VALIDATE_URI
const REFRESH_URI = process.env.REACT_APP_REFRESH_TOKEN_URI
const REQUEST_TOKENS_URI = process.env.REACT_APP_REQUEST_TOKENS_TOKEN_URI

export const login = async (username: string, password: string) => {
    try {
        const response: AxiosResponse<AuthResponse> = await axios.post(`${API_BASE_URL}${LOGIN_URI}`, {username, password});
        const accessToken = response.data.accessToken;
        const refreshToken = response.data.refreshToken;
        saveTokens(accessToken, refreshToken);
        return ({accessToken, refreshToken}) as LoginResponse;
    } catch (error) {
        throw error;
    }
};
export const validateTokens = async () => {
    if (await isAccessTokenValid()) {
        return true
    } else if (await isRefreshTokenValid()) {
        return await refreshAccessToken();
    } else if (await isCookieValid()) {
        return await tradeCookieWithTokens();
    } else {
        return false
    }
};
const isAccessTokenValid = async () => {
    try {
        const accessToken = getAccessToken();
        await axios.get(`${API_BASE_URL}${VALIDATE_URI}`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            }
        });
        return true;
    } catch (error: any) {
        return false;
    }
};
const isRefreshTokenValid = async () => {
    try {
        const refreshToken = getRefreshToken();
        await axios.get(`${API_BASE_URL}${VALIDATE_URI}`, {
            headers: {
                'Authorization': `Bearer ${refreshToken}`,
            }
        });
        return true;
    } catch (error: any) {
        return false;
    }
};
const refreshAccessToken = async () => {
    try {
        const refreshToken = getRefreshToken();
        const response: AxiosResponse<AuthResponse> = await axios.get(`${API_BASE_URL}${REFRESH_URI}`, {
            headers: {
                'Authorization': `Bearer ${refreshToken}`,
            }
        });
        const newAccessToken = response.data.accessToken;
        const newRefreshToken = response.data.refreshToken;
        saveTokens(newAccessToken, newRefreshToken);
        return true
    } catch (error) {
        throw error;
    }
};

const isCookieValid = async () => {
    try {
        await axios.get(`${API_BASE_URL}${VALIDATE_URI}`, {
            withCredentials: true
        });
        return true;
    } catch (error: any) {
        return false;
    }

}
const tradeCookieWithTokens = async () => {
    try {
        const response: AxiosResponse<AuthResponse> = await axios.get(`${API_BASE_URL}${REQUEST_TOKENS_URI}`, {
            withCredentials: true
        });
        const newAccessToken = response.data.accessToken;
        const newRefreshToken = response.data.refreshToken;
        saveTokens(newAccessToken, newRefreshToken);
        return true
    } catch (error) {
        throw error;
    }
};