import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import '../../styles/login/css/style.scss';
import {logout} from '../login/authSlice';
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../app/store/configureStore";
import {removeTokens} from "../../services/jwtService";

const Logout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        removeTokens();
        const LOGIN_URI = process.env.REACT_APP_LOGIN_URI
        dispatch(logout());
        navigate(LOGIN_URI);
    }, [dispatch, navigate]);

    return (
        <div className="login-container"><span className='loader'></span></div>
    );
};

export default Logout;