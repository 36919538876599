import React from 'react';

function HomePage() {
    return (
        <div style={styles.box}>
            <h1 style={styles.typography}>
                Welcome to the store home!
            </h1>
        </div>
    );
}

const styles: { [key: string]: React.CSSProperties } = {
    box: {
        display: 'flex',
        justifyContent: 'center',
        padding: '32px',
    },
    typography: {
        fontSize: '2em',
    },
};

export default HomePage;
