import aglowLogo from "../../assets/images/logo-aglow.svg";
import payleadrLogo from "../../assets/images/logo-payleadr.png";
import React from "react";

export default function LoginPageHeader() {
    const AGLOW_URL = process.env.REACT_APP_AGLOW_WEBSITE_URL
    const PAYLEADR_URL = process.env.REACT_APP_PAYLEADR_WEBSITE_URL
    return (
        // href to be fixed, use env files
        <header>
            <div className="brand">
                <a href={AGLOW_URL} target="_blank" rel="noreferrer">
                    <img src={aglowLogo}
                         className="logo-top-img"
                         alt="aglow"
                         loading="lazy"/>
                </a>
            </div>
            <div className="brand">
                <a href={PAYLEADR_URL} target="_blank" rel="noreferrer">
                    <img src={payleadrLogo}
                         className="logo-top-img"
                         alt="Payleadr"
                         loading="lazy"/>
                </a>
            </div>
        </header>
    )
}