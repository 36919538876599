export  default function LoginPageFooter(){
    return (
        <footer className="footer">
            <div className="container-fuild">
                <p>
                    <a href="https://payleadr.com/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a>
                </p>
                <p>
                    © {new Date().getFullYear()} Payleadr Pty Ltd. All Rights Reserved
                </p>
            </div>
        </footer>
    )
}