import {createBrowserRouter, Navigate} from "react-router-dom";
import {Login, Logout, HomePage, AboutPage, AuditLogs, ContactPage} from "../../features";
import App from "../layout/App";
import {ServerError, NotFound} from "../errors";

const PUBLIC_URI = process.env.PUBLIC_URL;
export const router = createBrowserRouter([
    {
        path: PUBLIC_URI,
        element: <App/>,
        children: [

            {path: 'home', element: <HomePage/>},
            {path: 'auditLogs', element: <AuditLogs/>},
            {path: 'about', element: <AboutPage/>},
            // {path: 'contact', element: <ContactPage />},
            {path: 'server-error', element: <ServerError/>},
            {path: 'not-found', element: <NotFound/>},
            {path: 'login', element: <Login/>},
            {path: 'logout', element: <Logout/>},
            {path: 'contact', element: <ContactPage/>},
            {path: PUBLIC_URI, element: <Navigate replace to={PUBLIC_URI + '/login'}/>},
            {path: '*', element: <Navigate replace to={PUBLIC_URI + '/not-found'}/>}
        ]
    }
])