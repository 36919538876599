import React from 'react';
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import { decrement, increment } from "./counterSlice";

const ContactPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const { data, title } = useAppSelector(state => state.counter);

    return (
        <>
            <h3 style={styles.title}>
                {title} test
            </h3>
            <h5 style={styles.subtitle}>
                The Data is: {data}
            </h5>
            <div style={styles.buttonGroup}>
                <button
                    onClick={() => dispatch(decrement(1))}
                    style={{ ...styles.button, backgroundColor: 'red' }}
                >
                    Decrement
                </button>
                <button
                    onClick={() => dispatch(increment(1))}
                    style={{ ...styles.button, backgroundColor: 'gray' }}
                >
                    Increment
                </button>
                <button
                    onClick={() => dispatch(increment(5))}
                    style={{ ...styles.button, backgroundColor: 'blue' }}
                >
                    Increment by 5
                </button>
            </div>
        </>
    );
};

const styles: { [key: string]: React.CSSProperties } = {
    title: {
        marginBottom: '16px',
        fontSize: '1.75em',
    },
    subtitle: {
        marginBottom: '16px',
        fontSize: '1.25em',
    },
    buttonGroup: {
        display: 'flex',
        gap: '10px',
    },
    button: {
        padding: '10px 20px',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
};

export default ContactPage;
